import React from "react";
import "./About.css"; // Import CSS file for styling

function About() {
  return (
    <section className="about">
      <h2>About</h2>
      <p>Welcome to Pretty Presents by Chana! </p>
      <br />
      <p>
        We specialize in creating beautifully wrapped gifts for all occasions.
        Whether it's birthdays, weddings, or holidays, we have the perfect
        present for you. We ensure that each gift is meticulously crafted to
        delight both the giver and the recipient. Browse our collection and make
        every moment special with Pretty Presents by Chana.
      </p>
      {/* Add more about your business here */}
    </section>
  );
}

export default About;
